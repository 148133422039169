exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-crimpd-plus-js": () => import("./../../../src/pages/crimpd-plus.js" /* webpackChunkName: "component---src-pages-crimpd-plus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../../../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-building-a-training-plan-en-mdx": () => import("./../../../src/templates/docs-page.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/en/pages/docs/building-a-training-plan.en.mdx" /* webpackChunkName: "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-building-a-training-plan-en-mdx" */),
  "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-getting-started-en-mdx": () => import("./../../../src/templates/docs-page.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/en/pages/docs/getting-started.en.mdx" /* webpackChunkName: "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-getting-started-en-mdx" */),
  "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-manage-subscription-en-mdx": () => import("./../../../src/templates/docs-page.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/en/pages/docs/manage-subscription.en.mdx" /* webpackChunkName: "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-manage-subscription-en-mdx" */),
  "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-redeem-en-mdx": () => import("./../../../src/templates/docs-page.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/en/pages/docs/redeem.en.mdx" /* webpackChunkName: "component---src-templates-docs-page-js-content-file-path-src-en-pages-docs-redeem-en-mdx" */),
  "component---src-templates-docs-page-js-content-file-path-src-es-pages-docs-lattice-training-plans-es-mdx": () => import("./../../../src/templates/docs-page.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/src/es/pages/docs/lattice-training-plans.es.mdx" /* webpackChunkName: "component---src-templates-docs-page-js-content-file-path-src-es-pages-docs-lattice-training-plans-es-mdx" */)
}

